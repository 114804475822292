import React, { useEffect, useState } from 'react';
import { isItUndefined, modifyLinks, showPart } from '../util/DataUtil';
import Image from 'next/image';
import { brandColor, getClassTextForColor } from '../util/ColorUtil';
import { rgbToHex } from '@mui/material';
import Marketo from './marketo';
import { cleanHTML } from './utils/htmlUtils';

const downloadItemsLocationEnum = {
  LEFT: '0',
  RIGHT: '1',
};

const renderItems = (items, downloadItemsLocation, showDownloadItems) => {
  if (!items) {
    return;
  }

  return items?.promo.map((item, key) => {
    let filename = getFileName(item);
    let downloadUrl = getFileUrl(item);

    return (
      <div key={key} className={'item'}>
        {showPart(isItUndefined(item?.field_promo_cta_item_title)) && (
          <span
            className={'item-title'}
            dangerouslySetInnerHTML={{
              __html: modifyLinks(
                isItUndefined(item?.field_promo_cta_item_title)
              ),
            }}
          />
        )}
        {showPart(isItUndefined(item?.field_promo_cta_item_description)) && (
          <span
            className={'item-description'}
            dangerouslySetInnerHTML={{
              __html: modifyLinks(
                isItUndefined(item?.field_promo_cta_item_description)
              ),
            }}
          />
        )}
        {downloadItemsLocation === downloadItemsLocationEnum.RIGHT &&
          showDownloadItems !== false &&
          downloadUrl && (
            <a
              className={'item-button'}
              target={'_blank'}
              href={downloadUrl}
              download={filename}
            >
              {isItUndefined(item?.field_promo_cta_item_button_text)}
            </a>
          )}
      </div>
    );
  });
};

const renderItemsLeft = (items) => {
  if (!items) {
    return;
  }

  return items?.promo.map((item, key) => {
    let filename = getFileName(item);
    let downloadUrl = getFileUrl(item);

    return (
      <div key={key} className={'item'}>
        <div className={'text-wrapper'}>
          {showPart(isItUndefined(item?.field_promo_cta_item_title)) && (
            <span
              className={'item-title'}
              dangerouslySetInnerHTML={{
                __html: modifyLinks(
                  isItUndefined(item?.field_promo_cta_item_title)
                ),
              }}
            />
          )}
        </div>
        {downloadUrl && (
          <a
            className={'item-button'}
            target={'_blank'}
            href={downloadUrl}
            download={filename}
          >
            {isItUndefined(item?.field_promo_cta_item_button_text)}
          </a>
        )}
      </div>
    );
  });
};

const renderMarketoForm = (
  children,
  global,
  backgroundColor,
  setShowDownloadItems,
  lang
) => {
  return (
    <div
      className={'promo-marketo main-marketo'}
      style={{ backgroundColor: backgroundColor }}
    >
      <Marketo
        lang={lang}
        setShowDownloadItems={setShowDownloadItems}
        global={global}
      >
        {children}
      </Marketo>
    </div>
  );
};

const downloadAllFiles = (data) => {
  if (!data) {
    return;
  }

  let files = [];

  for (let item of data?.promo) {
    let filename = '';
    let downloadUrl = '';

    if (isItUndefined(item?.field_promo_cta_item_download_fi)) {
      filename = isItUndefined(item?.field_promo_cta_item_download_fi).replace(
        /^.*[\\\/]/,
        ''
      );
      downloadUrl = isItUndefined(item?.field_promo_cta_item_download_fi);
    } else if (isItUndefined(item?.field_promo_cta_item_marketo_url)) {
      filename = isItUndefined(item?.field_promo_cta_item_marketo_url).replace(
        /^.*[\\\/]/,
        ''
      );
      downloadUrl = isItUndefined(item?.field_promo_cta_item_marketo_url);
    }

    files.push({
      filename: filename,
      download: downloadUrl,
    });
  }

  triggerDownloadFiles(files);
};

const triggerDownloadFiles = (files) => {
  const downloadNext = (i) => {
    if (i >= files.length) {
      return;
    }

    let anchorElement = document.createElement('a');
    let fileName = files[i].filename;

    anchorElement.href = files[i].download;
    anchorElement.download = fileName;
    anchorElement.target = '_blank';
    document.body.appendChild(anchorElement);

    anchorElement.click();

    downloadNext(i + 1);
  };
  // Initiate the first download.
  downloadNext(0);
};

const getFileUrl = (item) => {
  let downloadUrl = '';

  if (isItUndefined(item?.field_promo_cta_item_download_fi)) {
    downloadUrl = isItUndefined(item?.field_promo_cta_item_download_fi);
  } else if (isItUndefined(item?.field_promo_cta_item_marketo_url)) {
    downloadUrl = isItUndefined(item?.field_promo_cta_item_marketo_url);
  }

  return downloadUrl;
};

const getFileName = (item) => {
  let filename = '';

  if (isItUndefined(item?.field_promo_cta_item_download_fi)) {
    filename = isItUndefined(item?.field_promo_cta_item_download_fi).replace(
      /^.*[\\\/]/,
      ''
    );
  } else if (isItUndefined(item?.field_promo_cta_item_marketo_url)) {
    filename = isItUndefined(item?.field_promo_cta_item_marketo_url).replace(
      /^.*[\\\/]/,
      ''
    );
  }

  return filename;
};

const PromoCtaWithForm = ({ children, global, lang }) => {
  if (!children) {
    return;
  }

  const [borderColor, setBorderColor] = useState('');
  const [showDownloadItems, setShowDownloadItems] = useState(false);

  let downloadItemsLocation = isItUndefined(
    children?.field_promo_cta_download_items_l
  )
    ? isItUndefined(children?.field_promo_cta_download_items_l)
    : '1';
  let backgroundColor = brandColor(
    isItUndefined(children?.field_background_color)
  );
  let textColor = getClassTextForColor(
    isItUndefined(children?.field_background_color)
  );
  let marketoForm = isItUndefined(
    children?.field_promo_cta_marketo_block,
    'marketo_form_facility_block_type'
  );
  let customButtonColor =
    isItUndefined(children?.field_background_color) === '0' ? ' white' : ' ';

  useEffect(() => {
    getColor(children?.field_promo_cta_title);
  }, []);

  const getColor = () => {
    if (typeof window !== undefined) {
      let title = document.querySelector('.promo-cta-wrapper .title span');

      if (!title) {
        return;
      }

      const compStyles = window.getComputedStyle(title);

      if (rgbToHex(compStyles.color) !== '#000000') {
        setBorderColor(compStyles?.color);
      }
    }
  };

  return (
    <div
      id={'promo-cta'}
      style={{ backgroundColor: backgroundColor }}
      className={textColor + customButtonColor}
    >
      <div className={'inner-container'}>
        <div className={'promo-cta-wrapper'}>
          <div className={'left'}>
            <div
              className={'image-wrapper'}
              style={{ borderColor: borderColor }}
            >
              {children?.field_promo_cta_image.length !== 0 && (
                <Image
                  priority
                  layout="fill"
                  alt="cta-image"
                  src={children?.field_promo_cta_image[0]}
                />
              )}
              <div className={'cta-text-wrapper'}>
                {showPart(isItUndefined(children?.field_promo_cta_text)) && (
                  <span
                    className={'cta-text'}
                    dangerouslySetInnerHTML={{
                      __html: cleanHTML(
                        modifyLinks(
                          isItUndefined(children?.field_promo_cta_text)
                        )
                      ),
                    }}
                  />
                )}
                {showPart(
                  isItUndefined(children.field_promo_cta_download_text)
                ) &&
                  showDownloadItems !== false && (
                    <div className={'download'}>
                      <img
                        className={'cta'}
                        alt="cta"
                        src={'/images/download-black.png'}
                      />
                      <span
                        onClick={() =>
                          downloadAllFiles(children?.field_promo_cta_items)
                        }
                      >
                        {isItUndefined(children?.field_promo_cta_download_text)}
                      </span>
                    </div>
                  )}
              </div>
            </div>
            {
              <div className={'items-left-wrapper'}>
                {renderMarketoForm(
                  marketoForm,
                  global,
                  backgroundColor,
                  setShowDownloadItems,
                  lang
                )}
                {downloadItemsLocation === downloadItemsLocationEnum.LEFT &&
                  showDownloadItems !== false &&
                  renderItemsLeft(children?.field_promo_cta_items)}
              </div>
            }
          </div>
          <div className={'right'}>
            <h1
              className={'title'}
              dangerouslySetInnerHTML={{
                __html: cleanHTML(
                  modifyLinks(isItUndefined(children?.field_promo_cta_title))
                ),
              }}
            />
            {showPart(isItUndefined(children?.field_promo_cta_description)) && (
              <span
                className={'description'}
                dangerouslySetInnerHTML={{
                  __html: cleanHTML(
                    modifyLinks(
                      isItUndefined(children?.field_promo_cta_description)
                    )
                  ),
                }}
              />
            )}
            <div className={'items-wrapper'}>
              {renderItems(
                children?.field_promo_cta_items,
                downloadItemsLocation,
                showDownloadItems
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromoCtaWithForm;
