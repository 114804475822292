import Link from 'next/link';
import { useRouter } from 'next/router';
import { isLinkAbsolute, modifyLinks } from '../../util/DataUtil';
import { Constants } from '../../util/Constants';
import { getEditorColor } from '../../util/ColorUtil';
import { useState } from 'react';
import { cleanHTML } from '../utils/htmlUtils';

const ContentImage = ({ data }) => {
  const router = useRouter();
  const emphasizedTextColor = getEditorColor(
    data?.field_content_image_text[0]?.value
  );
  const [isHoverActive, setIsHoverActive] = useState(false);

  const correctInternalLink = (link) => {
    if (!link) return;

    if (link.includes('internal')) {
      return link.substring(9);
    }
    return link;
  };
  return (
    <div className={'content-image black div-content-image-link'}>
      <div
        onMouseEnter={() => setIsHoverActive(true)}
        onMouseLeave={() => setIsHoverActive(false)}
        className={`div-content-image-link left${isHoverActive ? ' hovered' : ''}`}
        onClick={() => {
          let link = correctInternalLink(data.field_link[0]?.uri);

          if (isLinkAbsolute(link)) {
            window.open(link, '_blank');
          } else {
            router.push(link);
          }
        }}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: cleanHTML(
              modifyLinks(data?.field_content_image_text[0]?.value)
            ),
          }}
          className={`title ${emphasizedTextColor ? emphasizedTextColor : ''}`}
        />
        {data?.field_link[0]?.title ? (
          <div
            className={`cta-container ${emphasizedTextColor ? emphasizedTextColor : ''}`}
          >
            <Link
              href={
                data?.field_link[0]?.uri
                  ? correctInternalLink(data?.field_link[0]?.uri)
                  : ''
              }
              passHref
              legacyBehavior
            >
              <p className="inline">
                <a
                  target={
                    isLinkAbsolute(data?.field_link[0]?.uri)
                      ? '_blank'
                      : '_self'
                  }
                  className={`a-content-image-link cta-link ${emphasizedTextColor ? emphasizedTextColor : ''}`}
                >
                  {data?.field_link[0]?.title}
                </a>
              </p>
            </Link>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div
        className={'right'}
        style={{
          backgroundImage:
            'url(' +
            Constants.cloudinaryImage +
            data?.field_content_image_image[0] +
            ')',
        }}
      />
    </div>
  );
};

export default ContentImage;
