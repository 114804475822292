export const domains = [
  {
    en: {
      localhost: 'localhost:3000',
      dlr: 'https://dlr-frontend.getdeveloper.net',
      uat: 'https://uat.digitalrealty.com',
      production: 'https://www.digitalrealty.com',
    },
  },
  {
    'en-gb': {
      localhost: 'localhost:3000',
      dlr: 'https://dlr-uk.getdeveloper.net',
      uat: 'https://uat-uk.digitalrealty.com',
      production: 'https://www.digitalrealty.co.uk',
    },
  },
  {
    de: {
      localhost: 'localhost:3000',
      dlr: 'https://dlr-de.getdeveloper.net',
      uat: 'https://uat-de.digitalrealty.com',
      production: 'https://www.digitalrealty.de',
    },
  },
  {
    fr: {
      localhost: 'localhost:3000',
      dlr: 'https://dlr-fr.getdeveloper.net',
      uat: 'https://uat-fr.digitalrealty.com',
      production: 'https://www.digitalrealty.fr',
    },
  },
  {
    nl: {
      localhost: 'localhost:3000',
      dlr: 'https://dlr-nl.getdeveloper.net',
      uat: 'https://uat-nl.digitalrealty.com',
      production: 'https://www.digitalrealty.nl',
    },
  },
  {
    ko: {
      localhost: 'localhost:3000',
      dlr: 'https://dlr-kr.getdeveloper.net',
      uat: 'https://uat-kr.digitalrealty.com',
      production: 'https://www.digitalrealty.kr',
    },
  },
  {
    'zh-hans': {
      localhost: 'localhost:3000',
      dlr: 'https://dlr-cn.getdeveloper.net',
      uat: 'https://uat-cn.digitalrealty.com',
      production: 'https://www.digitalrealty.cn',
    },
  },
  {
    ja: {
      localhost: 'localhost:3000',
      dlr: 'https://dlr-jp.getdeveloper.net',
      uat: 'https://uat-jp.digitalrealty.com',
      production: 'https://www.digitalrealty.jp',
    },
  },
  {
    es: {
      localhost: 'localhost:3000',
      dlr: 'https://dlr-es.getdeveloper.net',
      uat: 'https://uat-es.digitalrealty.com',
      production: 'https://www.digitalrealty.es',
    },
  },
  {
    asia: {
      localhost: 'localhost:3000',
      dlr: 'https://dlr-asia.getdeveloper.net',
      uat: 'https://uat-asia.digitalrealty.com',
      production: 'https://www.digitalrealty.asia',
    },
  },
  {
    at: {
      localhost: 'localhost:3000',
      dlr: 'https://dlr-at.getdeveloper.net',
      uat: 'https://uat-at.digitalrealty.com',
      production: 'https://www.digitalrealty.at',
    },
  },
  {
    ch: {
      localhost: 'localhost:3000',
      dlr: 'https://dlr-ch.getdeveloper.net',
      uat: 'https://uat-ch.digitalrealty.com',
      production: 'https://ch.digitalrealty.com',
    },
  },
  {
    se: {
      localhost: 'localhost:3000',
      dlr: 'https://dlr-se.getdeveloper.net',
      uat: 'https://uat-se.digitalrealty.com',
      production: 'https://www.digitalrealty.se',
    },
  },
  {
    dk: {
      localhost: 'localhost:3000',
      dlr: 'https://dlr-dk.getdeveloper.net',
      uat: 'https://uat-dk.digitalrealty.com',
      production: 'https://www.digitalrealty.dk',
    },
  },
  {
    ie: {
      localhost: 'localhost:3000',
      dlr: 'https://dlr-ie.getdeveloper.net',
      uat: 'https://uat-ie.digitalrealty.com',
      production: 'https://www.digitalrealty.ie',
    },
  },
  {
    hr: {
      localhost: 'localhost:3000',
      dlr: 'https://dlr-hr.getdeveloper.net',
      uat: 'https://uat-hr.digitalrealty.com',
      production: 'https://digitalrealty.hr',
    },
  },
  {
    be: {
      localhost: 'localhost:3000',
      dlr: 'https://dlr-be.getdeveloper.net',
      uat: 'https://uat-be.digitalrealty.com',
      production: 'https://digitalrealty.be',
    },
  },
  {
    el: {
      localhost: 'localhost:3000',
      dlr: 'https://dlr-gr.getdeveloper.net',
      uat: 'https://uat-gr.digitalrealty.com',
      production: 'https://digitalrealty.gr',
    },
  },
];
