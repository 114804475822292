import Image from 'next/image';
import { useState } from 'react';
import { renderColor } from '../../util/ColorUtil';
import { getObjectValueByMachineName } from '../../util/ObjectUtil';
import CtaBarButton from '../base/cta-bar-button';
import FacilityModal from '../modal/FacilityModal';
import {
  getEditorColors,
  isItUndefined,
  modifyLinks,
} from '../../util/DataUtil';
import { cleanHTML } from '../utils/htmlUtils';

const TemplateSupergraphic3D = ({
  children,
  regionData,
  facilityData,
  locationsData,
  metroData,
  productsData,
  regionColor,
  global,
  lang,
}) => {
  const [marketoTrigger, setMarketoTrigger] = useState(false);
  const emphasizedTextColor = getEditorColors(
    isItUndefined(children?.field_formatted_title),
    isItUndefined(children?.field_description)
  ).filter((x) => x)[0];

  const dataSet = {
    ...metroData,
    ...productsData,
    ...locationsData,
    ...regionData,
    ...facilityData,
  };
  return (
    <>
      <div
        className={`template-supergraphic-3d ${renderColor(children?.field_text_color[0]?.value, regionColor)}`}
      >
        <div className="inner-container">
          <div className="left">
            {children?.field_formatted_title[0]?.value && (
              <div
                dangerouslySetInnerHTML={{
                  __html: cleanHTML(
                    modifyLinks(children?.field_formatted_title[0]?.value)
                  ),
                }}
                className={`headline ${emphasizedTextColor ? emphasizedTextColor : ''}`}
              ></div>
            )}
            {children?.field_description[0]?.value && (
              <div
                dangerouslySetInnerHTML={{
                  __html: cleanHTML(
                    modifyLinks(
                      getObjectValueByMachineName(
                        children?.field_description[0]?.value,
                        dataSet
                      )
                    )
                  ),
                }}
                className={`description ${emphasizedTextColor ? emphasizedTextColor : ''}`}
              ></div>
            )}
            {children?.field_cta_bar_button_selection?.length > 0 && (
              <CtaBarButton
                blockTypeClass={'supergraphic-3d'}
                bgColor={renderColor(
                  children?.field_text_color[0]?.value,
                  regionColor
                )}
                setMarketoTrigger={setMarketoTrigger}
                data={
                  children?.field_cta_bar_button_selection[0]?.cta_bar_button
                }
              />
            )}
            {children?.field_stat[0]?.value && (
              <div
                dangerouslySetInnerHTML={{
                  __html: cleanHTML(
                    getObjectValueByMachineName(
                      children.field_stat[0]?.value,
                      dataSet
                    )
                  ),
                }}
                className="stat-value"
              ></div>
            )}
            {children?.field_stat_descriptive_text[0]?.value && (
              <div
                dangerouslySetInnerHTML={{
                  __html: cleanHTML(
                    modifyLinks(children?.field_stat_descriptive_text[0]?.value)
                  ),
                }}
                className="stat-description"
              ></div>
            )}
          </div>
          <div className="right">
            <Image
              objectPosition="center"
              objectFit="cover"
              layout="fill"
              alt="supergraphic-image"
              src={children?.field_main_image?.webimage}
            />
          </div>
        </div>
      </div>
      {marketoTrigger && (
        <FacilityModal
          lang={lang}
          global={global}
          setTrigger={setMarketoTrigger}
          trigger={marketoTrigger}
        >
          {
            children?.field_cta_bar_button_selection[0]?.cta_bar_button
              ?.field_marketo_blocks[0]?.marketo_form_facility_block_type
          }
        </FacilityModal>
      )}
    </>
  );
};

export default TemplateSupergraphic3D;
