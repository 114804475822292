import { getEditorColor } from '../../util/ColorUtil';
import {
  getQuoteContainerClass,
  isItUndefined,
  modifyLinks,
} from '../../util/DataUtil';
import { cleanHTML } from '../utils/htmlUtils';

const TemplateQuote = ({ children, color, itemCount }) => {
  const emphasizedTextColor = getEditorColor(
    isItUndefined(children.field_template_quote_text)
  );
  return (
    <div className={'template-text template-quote'}>
      {children?.field_template_quote_text[0]?.value && (
        <div className={'quote'}>
          <div style={{ backgroundColor: color }} className={'quote-line'} />
          {/*<span className={'text-quote'}>{children.field_template_quote[0]?.value}</span>*/}
          <span
            className={`text-quote ${emphasizedTextColor ? emphasizedTextColor : ''}`}
            dangerouslySetInnerHTML={{
              __html: cleanHTML(
                modifyLinks(isItUndefined(children?.field_template_quote_text))
              ),
            }}
          />
          {/*<div className={'container'}>*/}
          <div className={getQuoteContainerClass(children)}>
            {isItUndefined(children?.field_template_quote_name) && (
              <span className={'char'}></span>
            )}
            <div className={'sub-cont'}>
              <span className={'name'}>
                {isItUndefined(children?.field_template_quote_name)}{' '}
              </span>
              {isItUndefined(children?.field_template_quote_name) &&
                isItUndefined(children?.field_template_quote_job) && (
                  <span>, </span>
                )}
              <span className={'job'}>
                {isItUndefined(children?.field_template_quote_job)}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TemplateQuote;
