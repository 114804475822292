import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ErrorVariant } from '../constants/ErrorVariant';
import { setHideBreadCrumbs, setHideFooter } from '../store/pageSlice';
import { isItUndefined } from '../util/DataUtil';

const Errors = ({ data }) => {
  const dispatch = useDispatch();
  const [searchInputValue, setSearchInputValue] = useState('');
  const router = useRouter();

  useEffect(() => {
    dispatch(setHideBreadCrumbs(true));
    dispatch(setHideFooter(true));

    return () => {
      dispatch(setHideBreadCrumbs(false));
      dispatch(setHideFooter(false));
    };
  });

  const keyDown = (e) => {
    if (e.keyCode !== 13) {
      return;
    }

    router.push({
      pathname: '/global-search-page',
      query: { search: searchInputValue },
    });
  };

  return (
    <div className="error-page">
      <div className="top">
        <span className="background-number">
          {isItUndefined(data?.field_error_codes) == ErrorVariant[403]
            ? 403
            : 404}
        </span>
        <span
          dangerouslySetInnerHTML={{
            __html: isItUndefined(data?.field_error_message),
          }}
          className="background-text"
        />
      </div>
      <div className="bottom">
        <div className="search-container">
          <img
            alt="search"
            className="search-icon"
            src="/images/search-icon-thick.svg"
          />
          <input
            onChange={(e) => setSearchInputValue(e.target.value)}
            onKeyDown={keyDown}
            value={searchInputValue}
            placeholder="Search insights, services and colocations"
          ></input>
        </div>
      </div>
    </div>
  );
};

export default Errors;
