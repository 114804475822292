import { renderIndividualProductTemplateBlocks } from '../../util/BlockUtil';

export const IndividualProductTemplate = ({
  data,
  type,
  lang,
  global,
  width,
  popular_downloads_posts,
  related_content,
  tags,
}) => {
  return (
    <div className={type.replace(/\s/g, '')}>
      {renderIndividualProductTemplateBlocks({
        data,
        width,
        lang,
        global,
        categories: data.categories,
        locations: data.locations,
        popular_downloads_posts,
        related_content,
        tags,
      })}
    </div>
  );
};
