import { renderBlocks } from '../../util/BlockUtil';

export const Templates = ({
  data,
  type,
  lang,
  global,
  width,
  popular_downloads_posts,
  related_content,
  tags,
}) => {
  return (
    <div className={type.replace(/\s/g, '')}>
      {renderBlocks(
        data,
        width,
        lang,
        global,
        popular_downloads_posts,
        related_content,
        tags
      )}
    </div>
  );
};
