import { useState } from 'react';
import { renderColor } from '../../util/ColorUtil';
import { isItUndefined } from '../../util/DataUtil';
import { getObjectValueByMachineName } from '../../util/ObjectUtil';
import MarketoContactUsInPage from '../marketo-form-contact-us-in-page';

const TemplateContactInfo = ({ children, global, lang }) => {
  const [isFormSubmitted, seIsFormSubmitted] = useState(false);

  const handleClick = (value, isEmail) => () => {
    if (isEmail) {
      window.location.href = `mailto:${value}`;
    } else {
      window.location.href = `tel:${value}`;
    }
  };

  const rednerMultipleValues = (values) => {
    if (!values) return;

    return values.map((e, key) => {
      return `<span key={${key}}>${e?.value}</span>`;
    });
  };

  const renderData = (data, isEmail = false) => {
    if (!data) return;

    return data?.map((item, index) => {
      if (
        !getObjectValueByMachineName(
          isItUndefined(item?.field_values),
          global,
          true
        )
      )
        return;

      return (
        <div
          className="contact-info-container"
          key={`contact-info-container-${index}`}
        >
          <span className="contact-info-label">
            {getObjectValueByMachineName(
              isItUndefined(item?.field_label_title),
              global
            )}
          </span>
          <span
            onClick={handleClick(
              getObjectValueByMachineName(
                isItUndefined(item?.field_values),
                global,
                true
              ),
              isEmail
            )}
            dangerouslySetInnerHTML={{
              __html:
                item?.field_values?.length == 1
                  ? getObjectValueByMachineName(
                      isItUndefined(item?.field_values),
                      global,
                      true
                    ).replace(',', ' ')
                  : rednerMultipleValues(item?.field_values)
                      .join(',')
                      .replace(/,/g, ''),
            }}
            className="contact-info-value"
          />
        </div>
      );
    });
  };

  const renderSections = (data) => {
    if (!data) return;

    return data?.map((item, index) => {
      let isEmailLong = false;
      item?.field_emails?.contact_info_emails_paragraph_ty?.forEach((e) => {
        if (isItUndefined(e?.field_values)) {
          if (e.field_values[0].value.length > 27) {
            isEmailLong = true;
          }
        }
      });
      if (!item) return;
      if (item?.field_section_options?.[0]?.value === 'Same section') {
        return (
          <div
            className="section-container same-section"
            key={`section-container-${index}`}
          >
            <span
              className="label"
              dangerouslySetInnerHTML={{
                __html: item?.field_title_text_title[0]?.value,
              }}
            />
            <div className="section-wrapper">
              <div className="contact-numbers-container">
                {renderData(
                  item?.field_items?.contact_info_items_paragraph_typ
                )}
              </div>
              <div
                className={`contact-emails-container ${isEmailLong ? '' : 'flex-pos'}`}
              >
                {renderData(
                  item?.field_emails?.contact_info_emails_paragraph_ty,
                  true
                )}
              </div>
            </div>
          </div>
        );
      }
      return (
        <div className="section-container" key={`section-container-${index}`}>
          <span
            className="label"
            dangerouslySetInnerHTML={{
              __html: item?.field_title_text_title[0]?.value,
            }}
          />
          <div className="contact-numbers-container">
            {renderData(item?.field_items?.contact_info_items_paragraph_typ)}
          </div>
          <div className="contact-emails-container">
            {renderData(
              item?.field_emails?.contact_info_emails_paragraph_ty,
              true
            )}
          </div>
        </div>
      );
    });
  };
  return (
    <div
      id={'template-contact-info'}
      className={`template-contact-info ${renderColor(children.field_text_color[0]?.value)}`}
    >
      <div className="inner-container">
        <div className="left">
          {renderSections(
            children?.field_contact_info_items?.contact_info_paragraph_type
          )}
        </div>
        {children?.field_marketo_block?.length > 0 ? (
          <div className="right">
            {/*<div className="marketo-form-container main-marketo">*/}
            <div className="contact-us-form">
              {children?.field_marketo_block?.length > 0 &&
              !isFormSubmitted &&
              children?.field_marketo_block[0]?.marketo_form_facility_block_type
                ?.field_marketo_main_title[0]?.value ? (
                <h5
                  className="marketo-contact-us-tittle"
                  dangerouslySetInnerHTML={{
                    __html:
                      children?.field_marketo_block[0]
                        ?.marketo_form_facility_block_type
                        ?.field_marketo_main_title[0]?.value,
                  }}
                />
              ) : null}
              {children?.field_marketo_block?.length > 0 &&
              !isFormSubmitted &&
              children?.field_marketo_block[0]?.marketo_form_facility_block_type
                ?.field_marketo_main_description[0]?.value ? (
                <div
                  className="marketo-contact-us-description"
                  dangerouslySetInnerHTML={{
                    __html:
                      children?.field_marketo_block[0]
                        ?.marketo_form_facility_block_type
                        ?.field_marketo_main_description[0]?.value,
                  }}
                />
              ) : null}
              {children?.field_marketo_block?.length > 0 &&
              !isFormSubmitted &&
              children?.field_marketo_block[0]?.marketo_form_facility_block_type
                ?.field_marketo_required_descripti?.[0]?.value ? (
                <div
                  className="marketo-contact-us-description description-required"
                  dangerouslySetInnerHTML={{
                    __html:
                      children?.field_marketo_block[0]
                        ?.marketo_form_facility_block_type
                        ?.field_marketo_required_descripti[0]?.value,
                  }}
                />
              ) : null}
              <MarketoContactUsInPage
                isTemplateInfo={true}
                seIsFormSubmitted={seIsFormSubmitted}
                lang={lang}
                global={global}
              >
                {children?.field_marketo_block?.length > 0
                  ? children?.field_marketo_block[0]
                      ?.marketo_form_facility_block_type
                  : []}
              </MarketoContactUsInPage>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default TemplateContactInfo;
