import { isBigScreen, isDesktop, isHD, isMobile, isTablet } from './DataUtil';

export const handleSeeMoreMetro = (screenWidth) => {
  if (!screenWidth) return 2;

  if (isMobile(screenWidth)) {
    return 2;
  } else if (isTablet(screenWidth)) {
    return 3;
  } else if (isBigScreen(screenWidth)) {
    return 2;
  } else if (isHD(screenWidth)) {
    return 2;
  }
  return;
};

export const handleSeeMoreFacility = (screenWidth) => {
  if (!screenWidth) return 9;
  if (isMobile(screenWidth)) {
    return 2;
  } else if (isTablet(screenWidth)) {
    return 6;
  } else if (isBigScreen(screenWidth)) {
    return 9;
  } else if (isHD(screenWidth)) {
    return 12;
  }
  return;
};

export const renderNoResult = (data, clearAll) => {
  return (
    <div className={'no-result'}>
      <h2 className="results-text">
        {data?.field_results_label?.[0]?.value || 'Results'}
      </h2>
      <h2 className="no-result-text">
        {data?.field_no_results_text?.[0]?.value ||
          'No results were found. Please check the spelling, reset any filters and try again.'}
      </h2>
      <div
        className={'clear-all'}
        onClick={() => {
          clearAll();
        }}
      >
        {data?.field_clear_all_label?.[0]?.value || 'Clear all search terms.'}
      </div>
    </div>
  );
};

export const excludeMetroDuplicateIDs = (array) => {
  const uniqueArray = [];
  const uniqueIDs = new Set();
  const uniqueURLs = new Set();

  array.forEach((obj) => {
    let id;
    let url;

    if (obj.node_id) {
      id = obj.node_id;
    } else if (obj.search_api_id) {
      id = obj.search_api_id.split('/')[1].split(':')[0];
    }

    if (obj.url) {
      url = obj.url;
    } else if (obj.search_api_id) {
      url = obj.search_api_id.split('/')[1].split(':')[0];
    }

    if (id && url && !uniqueIDs.has(id) && !uniqueURLs.has(url)) {
      uniqueIDs.add(id);
      uniqueURLs.add(url);
      uniqueArray.push(obj);
    }
  });

  return uniqueArray;
};

export const getInfoBoxText = (text, numberOfFacilities) => {
  if (!text) {
    return;
  }

  let matches = text.match(/\[(.*?)\]/);
  if (!matches) {
    return text;
  }

  let submatch = matches[1].slice(1);

  return text.replace(`[[${submatch}]]`, numberOfFacilities);
};

export const getAvaliableCloudFilters = (metro, facilities) => {
  if (!facilities?.length || !metro) return;

  let result = [];

  facilities.map((item) => {
    if (metro == item.metro) {
      result.push(item.field_available_clouds);
    }
  });

  return result;
};

// export const getAvaliableCloudTetheredFilters = (metro, facilities) => {
//     if(!facilities?.length || !metro) return;

//     let result = [];

//     facilities.map(item => {
//         if(metro == item.metro){
//             if(item.field_available_tethered_t_acces.length > 0){
//                 result.push(item.field_available_tethered_t_acces)
//             }
//         }
//     })

//     return result;
// }

export const getAvaliableCloudTetheredFilters = (metro, facilities) => {
  if (!facilities?.length || !metro) return [];

  return facilities
    .filter(
      (item) =>
        metro === item.metro &&
        item?.field_available_tethered_t_acces?.length > 0
    )
    .map((item) => item.field_available_tethered_t_acces);
};

export const getMetricSystemForLanguage = (lang) => {
  return lang === 'en' ? 'Imperial' : 'Metric';
};
