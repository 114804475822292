export const cleanHTML = (input) => {
  return input?.replace(/&nbsp;/g, ' ') || '';
};

export const getContentBetweenATags = (text) => {
  const regex = /<a[^>]*>(.*?)<\/a>/;
  const match = regex.exec(text);
  return match ? match[1] : null;
};

export const findCloudinaryPath = (data, name) => {
  for (const item of data) {
    if (item.name === name) {
      return item.field_footer_cloudinary_icon;
    }
  }
  return null; // Return null if name is not found
};
