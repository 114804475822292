import '../public/css/style.css';
import { wrapper } from '../store/store';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
config.autoAddCss = false;
import ErrorBoundary from '../components/base/ErrorBoundary';

function MyApp({ Component, pageProps }) {
  return (
    <ErrorBoundary
      onError={(error, errorInfo) => console.log({ error, errorInfo })}
    >
      <Component {...pageProps} />
    </ErrorBoundary>
  );
}
export default wrapper.withRedux(MyApp);
