import { Fragment } from 'react';
import { Constants } from './Constants';
export const getScriptForLanguage = (lang, isIxnquery) => {
  const scriptConfig = {
    nl: {
      domainScript: '549d5d3f-f431-4a46-ab40-7c3e8f334715',
    },
    'en-gb': {
      domainScript: '55bc6599-82ca-4c1b-a384-c955feab9ea5',
    },
    de: {
      domainScript: '4ea18404-90d8-4864-8153-459b9dcc47aa',
    },
    ko: {
      domainScript: '11a5c22e-ed4a-45cd-8e78-2286cd7bc644',
    },
    asia: {
      domainScript: '330f1c36-f361-431e-858e-c2bc93cca856',
    },
    'zh-hans': {
      domainScript: '08178bc7-a6dd-4a39-87c3-b48041f5fc09',
    },
    fr: {
      domainScript: 'bbc2df4f-0876-4bf0-b400-50589353c517',
    },
    es: {
      domainScript: '49da266b-47aa-40d7-8477-9e163fc24d38',
    },
    ch: {
      domainScript: '15e1300d-4821-4cfb-94e8-fe70691057a9',
    },
    at: {
      domainScript: '9e902633-1b82-4fe9-90c6-45db890d5476',
    },
    se: {
      domainScript: 'c73b20d9-6983-49d8-a60e-04dc633ae03d',
    },
    ie: {
      domainScript: '4fc9a59f-7111-4160-9d86-daa96a302e3d',
    },
    be: {
      domainScript: '22acf6cb-6ea9-4eac-ae72-7948924d7a44',
    },
    el: {
      domainScript: '4689a0af-d294-4a1c-aac0-0813d63b2ca0',
    },
    dk: {
      domainScript: '018deb70-2289-7660-89c6-0d8e44300af1',
    },
  };

  const defaultDomainScript = 'ec376bc6-9b0b-4f57-a6d8-2d6c73d285c5';

  if (isIxnquery) {
    return null;
  }

  const script = scriptConfig[lang];

  if (script) {
    return (
      <Fragment>
        <script
          src={`https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`}
          data-document-language="true"
          type="text/javascript"
          charset="UTF-8"
          data-domain-script={script.domainScript}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `function OptanonWrapper() { }`,
          }}
        />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <script
        src={`https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`}
        data-document-language="true"
        type="text/javascript"
        charset="UTF-8"
        data-domain-script={defaultDomainScript}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `function OptanonWrapper() { }`,
        }}
      />
    </Fragment>
  );
};

export const getCanonical = (canonicalUrl, lang, urlAlias) => {
  let canonFront = '';
  switch (lang) {
    case 'en':
      canonFront = Constants.en;
      break;
    case 'de':
      canonFront = Constants.de;
      break;
    case 'fr':
      canonFront = Constants.fr;
      break;
    case 'nl':
      canonFront = Constants.nl;
      break;
    case 'en-gb':
      canonFront = Constants.uk;
      break;
    case 'at':
      canonFront = Constants.at;
      break;
    case 'zh-hans':
      canonFront = Constants.cn;
      break;
    case 'ko':
      canonFront = Constants.kr;
      break;
    case 'el':
      canonFront = Constants.gr;
      break;
    case 'ch':
      canonFront = Constants.ch;
      break;
    case 'asia':
      canonFront = Constants.asia;
      break;
    case 'ja':
      canonFront = Constants.jp;
      break;
    case 'es':
      canonFront = Constants.es;
      break;
    case 'dk':
      canonFront = Constants.dk;
      break;
    case 'se':
      canonFront = Constants.se;
      break;
    case 'be':
      canonFront = Constants.be;
      break;
    case 'ie':
      canonFront = Constants.ie;
      break;
    default:
      break;
  }
  if (canonicalUrl) {
    return <link rel={'canonical'} href={canonFront + canonicalUrl} />;
  } else {
    let canUrlAlias = urlAlias;
    if (urlAlias == 'home') {
      canUrlAlias = '';
    }
    return <link rel={'canonical'} href={canonFront + '/' + canUrlAlias} />;
  }
};

export const getDomainAlias = (lang) => {
  const langMap = {
    en: 'en',
    'en-gb': 'uk',
    fr: 'fr',
    de: 'de',
    nl: 'nl',
    cn: 'zh-hans',
    kr: 'ko',
    asia: 'asia',
    es: 'es',
    at: 'at',
    ch: 'ch',
    se: 'se',
    dk: 'dk',
    ie: 'ie',
    hr: 'hr',
    be: 'be',
    gr: 'el',
  };

  return langMap[lang] || 'en';
};

export const getLanguageContent = (lang) => {
  switch (lang) {
    case 'asia':
    case 'ie':
    case 'se':
    case 'dk':
    case 'be':
      return 'en';
    case 'ch':
    case 'at':
      return 'de';
    default:
      return lang;
  }
};

export const renderAlternateLinks = (languages, Constants) => {
  if (!Array.isArray(languages)) {
    console.error('Expected languages to be an array but got:', languages);
    return null;
  }
  const existOnMainSite = languages.some(
    (lang) => Object.keys(lang)[0] === 'en'
  );
  const enLang = languages.find((lang) => lang.hasOwnProperty('en'));
  return (
    <>
      {existOnMainSite ? (
        enLang['en'] == '/home' ? (
          <link href={`${Constants.en}`} rel="alternate" hrefLang="x-default" />
        ) : (
          <link
            href={`${Constants.en}${enLang['en']}`}
            rel="alternate"
            hrefLang="x-default"
          />
        )
      ) : (
        <link href={Constants.en} rel="alternate" hrefLang="x-default" />
      )}
      {languages.map((lang) => {
        const langCode = Object.keys(lang)[0];
        const urlAlias = lang[langCode].replace(/^\/\//, '/'); // Remove double slashes
        switch (langCode) {
          case 'en':
            return (
              <link
                href={
                  urlAlias === '/home' ? Constants.en : Constants.en + urlAlias
                }
                rel="alternate"
                hreflang={'en-US'}
              />
            );
          case 'en-gb':
            return (
              <link
                href={
                  urlAlias === '/home' ? Constants.uk : Constants.uk + urlAlias
                }
                rel="alternate"
                hreflang={'en-GB'}
              />
            );
          case 'nl':
            return (
              <link
                href={
                  urlAlias === '/home' ? Constants.nl : Constants.nl + urlAlias
                }
                rel="alternate"
                hreflang={'nl-NL'}
              />
            );
          case 'de':
            return (
              <link
                href={
                  urlAlias === '/home' ? Constants.de : Constants.de + urlAlias
                }
                rel="alternate"
                hreflang={'de-DE'}
              />
            );
          case 'fr':
            return (
              <link
                href={
                  urlAlias === '/home' ? Constants.fr : Constants.fr + urlAlias
                }
                rel="alternate"
                hreflang={'fr-FR'}
              />
            );
          case 'ch':
            return (
              <link
                href={
                  urlAlias === '/home' ? Constants.ch : Constants.ch + urlAlias
                }
                rel="alternate"
                hreflang={'de-CH'}
              />
            );
          case 'es':
            return (
              <link
                href={
                  urlAlias === '/home' ? Constants.es : Constants.es + urlAlias
                }
                rel="alternate"
                hreflang={'es-ES'}
              />
            );
          case 'ko':
            return (
              <link
                href={
                  urlAlias === '/home' ? Constants.kr : Constants.kr + urlAlias
                }
                rel="alternate"
                hreflang={'ko-KR'}
              />
            );
          case 'zh-hans':
            return (
              <link
                href={
                  urlAlias === '/home' ? Constants.cn : Constants.cn + urlAlias
                }
                rel="alternate"
                hreflang={'zh-CN'}
              />
            );
          case 'at':
            return (
              <link
                href={
                  urlAlias === '/home' ? Constants.at : Constants.at + urlAlias
                }
                rel="alternate"
                hreflang={'de-AT'}
              />
            );
          case 'dk':
            return (
              <link
                href={
                  urlAlias === '/home' ? Constants.dk : Constants.dk + urlAlias
                }
                rel="alternate"
                hreflang={'en-dk'}
              />
            );
          case 'se':
            return (
              <link
                href={
                  urlAlias === '/home' ? Constants.se : Constants.se + urlAlias
                }
                rel="alternate"
                hreflang={'en-se'}
              />
            );
          case 'be':
            return (
              <link
                href={
                  urlAlias === '/home' ? Constants.be : Constants.be + urlAlias
                }
                rel="alternate"
                hreflang={'en-be'}
              />
            );
          case 'ie':
            return (
              <link
                href={
                  urlAlias === '/home' ? Constants.ie : Constants.ie + urlAlias
                }
                rel="alternate"
                hreflang={'en-ie'}
              />
            );
          case 'el':
            return (
              <link
                href={
                  urlAlias === '/home' ? Constants.gr : Constants.gr + urlAlias
                }
                rel="alternate"
                hreflang={'en-GR'}
              />
            );
          case 'asia':
            return (
              <>
                <link
                  href={
                    urlAlias === '/home'
                      ? Constants.asia
                      : Constants.asia + urlAlias
                  }
                  rel="alternate"
                  hreflang={'en-hk'}
                />
                <link
                  href={
                    urlAlias === '/home'
                      ? Constants.asia
                      : Constants.asia + urlAlias
                  }
                  rel="alternate"
                  hreflang={'en-MY'}
                />
                <link
                  href={
                    urlAlias === '/home'
                      ? Constants.asia
                      : Constants.asia + urlAlias
                  }
                  rel="alternate"
                  hreflang={'en-ID'}
                />
                <link
                  href={
                    urlAlias === '/home'
                      ? Constants.asia
                      : Constants.asia + urlAlias
                  }
                  rel="alternate"
                  hreflang={'en-TH'}
                />
                <link
                  href={
                    urlAlias === '/home'
                      ? Constants.asia
                      : Constants.asia + urlAlias
                  }
                  rel="alternate"
                  hreflang={'en-IN'}
                />
                <link
                  href={
                    urlAlias === '/home'
                      ? Constants.asia
                      : Constants.asia + urlAlias
                  }
                  rel="alternate"
                  hreflang={'en-MM'}
                />
                <link
                  href={
                    urlAlias === '/home'
                      ? Constants.asia
                      : Constants.asia + urlAlias
                  }
                  rel="alternate"
                  hreflang={'en-PH'}
                />
                <link
                  href={
                    urlAlias === '/home'
                      ? Constants.asia
                      : Constants.asia + urlAlias
                  }
                  rel="alternate"
                  hreflang={'en-NP'}
                />
                <link
                  href={
                    urlAlias === '/home'
                      ? Constants.asia
                      : Constants.asia + urlAlias
                  }
                  rel="alternate"
                  hreflang={'en-KH'}
                />
                <link
                  href={
                    urlAlias === '/home'
                      ? Constants.asia
                      : Constants.asia + urlAlias
                  }
                  rel="alternate"
                  hreflang={'en-SG'}
                />
                <link
                  href={
                    urlAlias === '/home'
                      ? Constants.asia
                      : Constants.asia + urlAlias
                  }
                  rel="alternate"
                  hreflang={'en-VN'}
                />
                <link
                  href={
                    urlAlias === '/home'
                      ? Constants.asia
                      : Constants.asia + urlAlias
                  }
                  rel="alternate"
                  hreflang={'en-BD'}
                />
                <link
                  href={
                    urlAlias === '/home'
                      ? Constants.asia
                      : Constants.asia + urlAlias
                  }
                  rel="alternate"
                  hreflang={'en-PK'}
                />
                <link
                  href={
                    urlAlias === '/home'
                      ? Constants.asia
                      : Constants.asia + urlAlias
                  }
                  rel="alternate"
                  hreflang={'en-LA'}
                />
                <link
                  href={
                    urlAlias === '/home'
                      ? Constants.asia
                      : Constants.asia + urlAlias
                  }
                  rel="alternate"
                  hreflang={'en-LK'}
                />
                <link
                  href={
                    urlAlias === '/home'
                      ? Constants.asia
                      : Constants.asia + urlAlias
                  }
                  rel="alternate"
                  hreflang={'en-KZ'}
                />
                <link
                  href={
                    urlAlias === '/home'
                      ? Constants.asia
                      : Constants.asia + urlAlias
                  }
                  rel="alternate"
                  hreflang={'en-MV'}
                />
              </>
            );
        }
      })}
    </>
  );
};

export const onScroll = () => {
  const html = document.querySelector('html');
  const thumb = document.querySelector('.overlay-scrollbar-thumb');
  // Calculate the percentage of the container that is scrolled

  let scrollPercentage =
    (html.scrollTop / (html.scrollHeight - html.clientHeight)) * 100;

  if (Math.sign(scrollPercentage) === -1) {
    scrollPercentage = 0;
  }

  if (thumb) {
    thumb.style.top = `${scrollPercentage}vh`;
  }
};

export function checkIsImage(items) {
  if (!items.length) return false;
  let ogImageIsSet = false;
  items.map((e) => {
    if (e && e[0] == 'og:image') {
      ogImageIsSet = true;
    }
  });
  return ogImageIsSet;
}

export const getLandPageFromRedirect = (lang, device, ixnRedirectMessages) => {
  if (!lang) return;

  if (lang === 'en-gb') {
    lang = 'uk';
  }

  const messages = ixnRedirectMessages[lang];

  return (
    <main>
      <div className="graybg-redirect"></div>
      <img
        src={`/images/interxion-${lang}-${device}.png`}
        className={`interxion-site image-${lang}`}
      />
      <div id="redirect-popup">
        <div id="first-row">
          <div>
            <img
              src="https://cms-digitalrealty.azureedge.net/sites/default/files/2023-04/Digital_Realty_TM_Brandmark_RGB_White_wthtqz_0.png"
              alt="Digital Realty Brandmark"
            />
          </div>
        </div>
        <div id="second-row">
          <h2>{messages?.[0]}</h2>
          <h5>{messages?.[1]}</h5>
        </div>
        <div id="third-row">
          <span
            onClick={() => {
              removeQueryParams();
              setisIxnQuery(false);
            }}
          >
            {messages?.[2]} &#8594;
          </span>
        </div>
      </div>
    </main>
  );
};

export const removeQueryParams = () => {
  const pathname = router.pathname;
  router.push(pathname);
};

export const getDevice = (viewportWidth) => {
  if (viewportWidth < 576) {
    return 'mobile';
  } else if (viewportWidth > 576 && viewportWidth < 1025) {
    return 'tablet';
  } else if (viewportWidth > 1025 && viewportWidth < 1920) {
    return 'desktop';
  } else {
    return 'xxl';
  }
};

export const setOperatingSystemClass = () => {
  if (typeof window !== 'undefined') {
    const userAgent = window.navigator.userAgent;
    let OSName = 'Unknown OS';

    if (typeof navigator !== 'undefined') {
      if (userAgent.indexOf('Win') !== -1) OSName = 'Windows';
      if (userAgent.indexOf('Mac') !== -1) OSName = 'MacOS';
      if (userAgent.indexOf('X11') !== -1) OSName = 'UNIX';
      if (userAgent.indexOf('Linux') !== -1) OSName = 'Linux';

      if (typeof document !== 'undefined') {
        document.getElementsByTagName('body')[0].classList.add(OSName.trim());
      }
    }
  }
};
