const ResourceTitle = ({ title, subtitle }) => {
  return (
    <>
      <div className={'resource-title'}>
        <div className={'inner-container'}>
          <div className={'title'}>{title}</div>
          <div className={'subtitle'}>{subtitle}</div>
          {/* <div className={'green-circle'}></div> */}
        </div>
      </div>
    </>
  );
};

export default ResourceTitle;
