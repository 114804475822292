import { Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { getEditorColor, renderColor } from '../../util/ColorUtil';
import {
  isItUndefined,
  getHoverColorLocation,
  modifyLinks,
} from '../../util/DataUtil';
import { getObjectValueByMachineName } from '../../util/ObjectUtil';
import { formatCommaSeparator } from '../../util/RegexUtil';
import CtaBarButton from '../base/cta-bar-button';
import FacilityModal from '../modal/FacilityModal';
import { cleanHTML } from '../utils/htmlUtils';

const TemplateTwoColumnStats = ({ children, lang, global }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [marketoTrigger, setMarketoTrigger] = useState(false);
  const [marketoData, setMarketoData] = useState([]);

  // const emphasizedTextColor = getEditorColors(isItUndefined(children?.field_template_text), isItUndefined(props.children?.field_template_text), isItUndefined(props.children?.field_description)).filter(x => x)[0];

  let ctaPosition =
    children?.field_cta_position[0]?.value == 0 ? 'left' : 'right';
  const textColor = getEditorColor(isItUndefined(children?.field_template_text))
    ? getEditorColor(isItUndefined(children?.field_template_text))
    : 'blue';

  if (ctaPosition == 'left') {
    if (children?.field_column_items?.two_columns_paragraph_type) {
      children.field_column_items.two_columns_paragraph_type[0].ctaPosition = true;
    }
  } else if (ctaPosition == 'right') {
    if (children?.field_column_items?.two_columns_paragraph_type.length >= 2) {
      children.field_column_items.two_columns_paragraph_type[1].ctaPosition = true;
    }
  }

  const renderBulets = (data) => {
    if (!data || data.length == 0) return;

    return data.map((item, key) => {
      return (
        <div key={`bullet-index-${key}`} className="bullet-item">
          <img
            style={{ filter: getHoverColorLocation(textColor) }}
            className="checkbox"
            alt="checkbox"
            src="/images/check-box.svg"
          />
          <span>{item?.value}</span>
        </div>
      );
    });
  };

  const renderStats = (data) => {
    if (!data || data.length == 0) return;
    return data.map((item, key) => {
      if (!item?.field_description_formatted[0]?.value) return;
      return (
        <div key={`stat-index-${key}`} className="stat">
          <div
            dangerouslySetInnerHTML={{
              __html: modifyLinks(
                formatCommaSeparator(
                  getObjectValueByMachineName(
                    item?.field_description_formatted[0]?.value,
                    global
                  )
                )
              ),
            }}
            className="label"
          ></div>
          <div
            dangerouslySetInnerHTML={{
              __html: modifyLinks(
                getObjectValueByMachineName(
                  item?.field_value_formatted[0]?.value,
                  global
                )
              ),
            }}
            className="value"
          ></div>
        </div>
      );
    });
  };

  const renderColumns = (data) => {
    if (!data) return;

    return (
      <>
        {isItUndefined(data?.field_title_text_text) && (
          <div
            className={`header`}
            dangerouslySetInnerHTML={{
              __html: modifyLinks(isItUndefined(data?.field_title_text_text)),
            }}
          ></div>
        )}
        {isItUndefined(data?.field_description) && (
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: modifyLinks(isItUndefined(data?.field_description)),
            }}
          ></div>
        )}
        {children?.field_cta_bar_button_selection?.length > 0 &&
        data?.ctaPosition ? (
          <CtaBarButton
            blockTypeClass={'two-column-stats'}
            click={setMarketoData}
            setMarketoTrigger={setMarketoTrigger}
            bgColor={renderColor(children.field_text_color[0]?.value)}
            data={children.field_cta_bar_button_selection[0].cta_bar_button}
          />
        ) : (
          <></>
        )}
        {isItUndefined(data?.field_title) && (
          <div
            className="bullets-title"
            dangerouslySetInnerHTML={{
              __html: modifyLinks(isItUndefined(data?.field_title)),
            }}
          ></div>
        )}
        {data?.field_bullets?.length > 0 ? (
          <div
            className={`bullets ${isItUndefined(data?.field_title) ? '' : 'no-bullet-title'}`}
          >
            {renderBulets(data?.field_bullets)}
          </div>
        ) : (
          <></>
        )}
        {data?.field_stats?.stats_coloured_fields_type.length > 0 &&
        data?.field_stats?.stats_coloured_fields_type[0]
          ?.field_description_formatted[0]?.value ? (
          <div className="stats">
            {renderStats(data?.field_stats?.stats_coloured_fields_type)}
          </div>
        ) : (
          <></>
        )}
        {data?.field_cta_bar_button_selection?.length > 0 ? (
          <CtaBarButton
            blockTypeClass={'two-column-stats'}
            click={setMarketoData}
            setMarketoTrigger={setMarketoTrigger}
            bgColor={renderColor(children?.field_text_color[0]?.value)}
            data={data?.field_cta_bar_button_selection[0]?.cta_bar_button}
          />
        ) : (
          <></>
        )}
      </>
    );
  };
  return (
    <>
      <div
        className={`template-two-columns-stats ${renderColor(children?.field_text_color[0]?.value)}`}
      >
        {children?.field_two_columns_variant[0]?.value == '1' ? (
          <>
            <div className="inner-container">
              <Tabs
                variant="scrollable"
                value={activeTab}
                onChange={(e, tab) => setActiveTab(tab)}
                className="two-column-tabs"
              >
                {isItUndefined(children?.field_title) ? (
                  <Tab
                    key={`two-column-tab-1`}
                    className="two-column-tab"
                    label={isItUndefined(children?.field_title)}
                  ></Tab>
                ) : (
                  <></>
                )}
                {isItUndefined(
                  children?.field_two_column_tab_items[0]
                    ?.two_column_stats_block_type?.field_title
                ) ? (
                  <Tab
                    key={`two-column-tab-2`}
                    className="two-column-tab"
                    label={isItUndefined(
                      children?.field_two_column_tab_items[0]
                        ?.two_column_stats_block_type?.field_title
                    )}
                  ></Tab>
                ) : (
                  <></>
                )}
              </Tabs>
            </div>
            <>
              <div className="inner-container">
                <div className="left-column">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getObjectValueByMachineName(
                        activeTab == 0
                          ? cleanHTML(
                              modifyLinks(
                                isItUndefined(children?.field_template_text)
                              )
                            )
                          : cleanHTML(
                              modifyLinks(
                                isItUndefined(
                                  children?.field_two_column_tab_items[0]
                                    ?.two_column_stats_block_type
                                    .field_template_text
                                )
                              ),
                              global
                            )
                      ),
                    }}
                    className="block-title"
                  />
                  {activeTab == 0
                    ? renderColumns(
                        children?.field_column_items
                          ?.two_columns_paragraph_type[0]
                      )
                    : renderColumns(
                        children?.field_two_column_tab_items[0]
                          ?.two_column_stats_block_type.field_column_items
                          ?.two_columns_paragraph_type[0]
                      )}
                </div>
                <div className="right-column">
                  {activeTab == 0 &&
                  children?.field_column_items?.two_columns_paragraph_type
                    .length == 2
                    ? renderColumns(
                        children?.field_column_items
                          ?.two_columns_paragraph_type[1]
                      )
                    : children?.field_two_column_tab_items[0]
                        ?.two_column_stats_block_type?.field_column_items
                        ?.two_columns_paragraph_type.length == 2 &&
                      renderColumns(
                        children?.field_two_column_tab_items[0]
                          ?.two_column_stats_block_type?.field_column_items
                          ?.two_columns_paragraph_type[1]
                      )}
                </div>
              </div>
              {children?.field_stats?.stats_coloured_fields_type.length > 0 ||
                (children?.field_two_column_tab_items[0]
                  ?.two_column_stats_block_type?.field_stats
                  .stats_coloured_fields_type.length > 0 && (
                  <div className="inner-container">
                    <div className="stats main">
                      {activeTab == 0
                        ? renderStats(
                            children?.field_stats?.stats_coloured_fields_type
                          )
                        : renderStats(
                            children?.field_two_column_tab_items[0]
                              ?.two_column_stats_block_type?.field_stats
                              ?.stats_coloured_fields_type
                          )}
                    </div>
                  </div>
                ))}
            </>
          </>
        ) : (
          <>
            <div className="inner-container">
              <div className="left-column">
                <div
                  dangerouslySetInnerHTML={{
                    __html: cleanHTML(
                      modifyLinks(
                        getObjectValueByMachineName(
                          isItUndefined(children?.field_template_text)
                        )
                      )
                    ),
                  }}
                  className="block-title"
                />
                {renderColumns(
                  children?.field_column_items?.two_columns_paragraph_type[0]
                )}
              </div>
              <div className="right-column">
                {children?.field_column_items?.two_columns_paragraph_type
                  ?.length == 2
                  ? renderColumns(
                      children?.field_column_items
                        ?.two_columns_paragraph_type[1]
                    )
                  : ''}
              </div>
            </div>
            {children?.field_stats?.stats_coloured_fields_type?.length > 0 &&
              children?.field_stats?.stats_coloured_fields_type[0]
                ?.field_description_formatted.length > 0 && (
                <div className="inner-container">
                  <div className="stats main">
                    {renderStats(
                      children?.field_stats?.stats_coloured_fields_type
                    )}
                  </div>
                </div>
              )}
          </>
        )}
      </div>
      {marketoTrigger && (
        <FacilityModal
          lang={lang}
          global={global}
          setTrigger={setMarketoTrigger}
          trigger={marketoTrigger}
        >
          {marketoData}
        </FacilityModal>
      )}
    </>
  );
};

export default TemplateTwoColumnStats;
