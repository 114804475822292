import React from 'react';
import { Constants, getBackendUrl } from '../../util/Constants';
import { getLanguage } from '../../util/PageUtil';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }

  // logError(errorData){
  //     const host = window.location.host;
  //     const url = getBackendUrl(host);

  //     const data = {
  //         dateTime: new Date().toISOString().slice(0, 19).replace('T', ' '),
  //         // error: 'Sample error message',
  //         errorMessage: errorData,
  //         // language: 'en',
  //         language: getLanguage(host),
  //         url: window.location.href
  //     };

  //     // fetch('http://html.test/apiv2-dlr/log-error', {
  //     fetch(url.remoteServer + 'log-error', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'secret' : 'ckfb7-x'
  //         },
  //         body: JSON.stringify(data)
  //     })
  //     .then(response => response.json())
  //     .then(data => {
  //         console.log('Success:', data);
  //     })
  //     .catch((error) => {
  //         console.error('Error:', error);
  //     });
  // }

  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    // LOG ERROR
    // this.logError({error, errorInfo})
    console.log({ error, errorInfo });
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h2>Oops, there is an error!</h2>
          <button
            type="button"
            onClick={() => this.setState({ hasError: false })}
          >
            Try again?
          </button>
        </div>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
