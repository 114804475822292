import React from 'react';
import Link from 'next/link';
import { formatIdStrings } from '../../../util/DataUtil';

const RenderParents = ({
  menu,
  mobile,
  currentHover,
  gray,
  setGray,
  setShowHamburger,
  setCurrentHover,
  setCurrentParentSelectedName,
  onBg,
  setCurrentChildSelected,
  router,
  onLeave,
  setOpenMenu,
  setLoginShow,
  languagesShow,
  setLanguagesShow,
  openMenu,
  offBg,
}) => {
  if (!menu) {
    return null;
  }

  let parents = [];

  menu.forEach((item, index) => {
    if (item?.name == 'Menu Logo') {
      return;
    }
    if (item?.field_only_tablet === 'On' && !mobile) {
      return '';
    }

    let isLink = !mobile && item.link && item.link !== '';

    parents.push(
      <span
        className={
          item.id === currentHover.id
            ? 'parent label active link-' + isLink
            : 'parent label link-' + isLink
        }
        onMouseEnter={() => {
          if (mobile) {
            return;
          }

          setGray(false);
          setShowHamburger(true);
          setCurrentHover(item);
          setCurrentParentSelectedName(item.name);
          onBg();
          item.children ? setCurrentChildSelected(item.children[0].id) : '';
        }}
        onClick={() => {
          if (item?.field_only_tablet === 'On' && mobile) {
            setLoginShow(true);
            setOpenMenu(false);
            setCurrentParentSelectedName(item.name);
            return;
          }

          setShowHamburger(true);
          setCurrentHover(item);
          setCurrentParentSelectedName(item.name);
          item.children ? setCurrentChildSelected(item.children[0].id) : '';

          if (isLink) {
            router.push(item.link.match(/href="([^"]*)/)[1]);
            onLeave();
            setOpenMenu(false);
            setCurrentParentSelectedName('');
          }
        }}
        key={'parent-' + item.id}
      >
        {item.link && !mobile ? (
          <Link
            href={
              item.link.match(/href="([^"]*)/)[1]
                ? item.link.match(/href="([^"]*)/)[1]
                : ''
            }
            passHref
          >
            <a id={`header-${formatIdStrings(item?.name)}-link`}>{item.name}</a>
          </Link>
        ) : (
          item.name
        )}
        <div
          className={
            item.id === currentHover.id
              ? gray
                ? 'activated gray'
                : 'activated green'
              : 'activated'
          }
        />
        <div className={'border'} />
      </span>
    );

    // Check if it's the second-to-last iteration
    if (index === menu.length - 2) {
      parents.push(
        <span
          className="parent label link-false"
          id="languages"
          key="languages"
          onClick={() => {
            setOpenMenu(!openMenu);
            setLanguagesShow(!languagesShow);
            setLoginShow(false);
            if (languagesShow) {
              offBg();
            } else {
              onBg();
            }
          }}
        >
          Language
          <div className="activated"></div>
          <div className="border"></div>
        </span>
      );
    }
  });

  return parents;
};

export default RenderParents;
