import React, { useState, useEffect, Fragment } from 'react';
import {
  applyTruncationForScreen,
  assemble,
  correctUrlFormatForRouting,
  correctDomainForLanguage,
  fetchData,
  isLinkAbsolute,
  marketoOptions as options,
  createFilterData,
  createFilterDataForResources,
  getAlgoliaDatasetValue,
  addQueryParamWithoutRedirecting,
  isItUndefined,
  getAlgoliaIndex,
  renderCardImage,
  createFiltersFromRedirect,
} from '../util/DataUtil';
import { getLanguage, useWindowSize } from '../util/PageUtil';
import { useRouter } from 'next/router';
import { Constants } from '../util/Constants';
import dynamic from 'next/dynamic';
import { useDispatch, useSelector } from 'react-redux';
import { setLocalStorageRedux } from '../store/localStorageSlice';
import { CircularProgress } from '@mui/material';

const ResourceWatch = dynamic(
  () => import('../components/resources/resource-watch')
);
const ResourcePopular = dynamic(
  () => import('../components/resources/resource-popular')
);
const ResourceTitle = dynamic(
  () => import('../components/resources/resource-title')
);
const ResourceLatest = dynamic(
  () => import('../components/resources/resource-latest')
);
const ResourceContent = dynamic(
  () => import('../components/resources/resource-content')
);
const ResourceFilter = dynamic(
  () => import('../components/resources/resource-filter')
);

export const Resources = ({ data, width, posts, global }) => {
  const router = useRouter();
  const windowSize = useWindowSize();
  const [query, setQuery] = useState([]);
  const [page, setPage] = useState(0);
  const [facetFilters, setFacetFilters] = useState([]);
  const [languageFilter, setLanguageFilter] = useState([]);
  const [results, setResults] = useState(false);
  const [applyFilter, setApplyFilter] = useState(false);
  const [algoliaData, setAlgoliaData] = useState([]);
  const [filterProps, setFilterProps] = useState([]);
  const [showMore, setShowMore] = useState([]);
  const [topSearchItems, setTopSearchItems] = useState([]);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [closeFilters, setCloseFilters] = useState(false);
  const myLocalStorage = useSelector(setLocalStorageRedux);

  let hitsPerPage = 16;
  let lng =
    typeof window !== 'undefined' ? getLanguage(window.location.hostname) : '';
  let facetFiltersArray = [];
  let lngArray = [];

  useEffect(() => {
    if (!lng) {
      return;
    }

    setIsLoading(true);
    fetchAlgoliaData();
    getMostPopularSearchTerms();
  }, [applyFilter, query, filterProps]);

  useEffect(() => {
    if (!lng) {
      return;
    }

    if (page > 0) {
      fetchAlgoliaPagination();
    }
  }, [page]);

  useEffect(() => {
    let localFilter = router.query;

    if (typeof window !== 'undefined') {
      // localFilter.length > 0 ? triggerFilterFromRedirect([{name: localFilter}]) : clearAll()
      hasNonEmptyProperties(localFilter)
        ? triggerFilterFromRedirect(localFilter)
        : clearAll();
    }
  }, [myLocalStorage]);

  function hasNonEmptyProperties(obj) {
    for (const key in obj) {
      if (
        key !== 'url' &&
        obj[key] !== null &&
        obj[key] !== undefined &&
        obj[key] !== ''
      ) {
        return true;
      }
    }
    return false;
  }

  const fetchAlgoliaData = () => {
    setResults(
      (query.length || facetFilters.length || languageFilter.length) !== 0
    );

    let url = createFilterDataForResources(
      lngArray,
      facetFiltersArray,
      facetFilters,
      query,
      hitsPerPage,
      page,
      lng,
      getAlgoliaDatasetValue(0) + getAlgoliaIndex()
    );

    const fetchAlgolia = async () => {
      await fetch(url, options)
        .then((response) => response.json())
        .then((newResponse) => {
          // setShowMore(newResponse.hits);
          setShowMore(filterOutNoContentType(newResponse.hits));
          setAlgoliaData(newResponse);
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
          // setIsLoading(false)
        });
    };
    fetchAlgolia();
  };

  const fetchAlgoliaPagination = () => {
    let url = createFilterDataForResources(
      lngArray,
      facetFiltersArray,
      facetFilters,
      query,
      hitsPerPage,
      page,
      lng,
      getAlgoliaDatasetValue(0) + getAlgoliaIndex()
    );

    const fetchAlgolia = async () => {
      await fetch(url, options)
        .then((response) => response.json())
        .then((newResponse) => {
          setShowMore((previousResponse) => [
            ...previousResponse,
            ...newResponse.hits,
          ]);
          setAlgoliaData(newResponse);
        });
    };

    fetchAlgolia();
  };

  const filterOutNoContentType = (data) =>
    data.filter((item) => item.content_type !== undefined);

  const fetchAlgoliaMostPopular = (queryParam) => {
    let lang = JSON.stringify('search_api_language:' + lng);
    // let url = 'https://SWULV5ONQ5-dsn.algolia.net/1/indexes/drupal?query=' + queryParam + '&facetFilters=[' + lang + ']&hitsPerPage=3&page=0';
    // let url = 'https://O2VJB2KMPB-dsn.algolia.net/1/indexes/drupalprod?query=' + queryParam + '&facetFilters=[' + lang + ']&hitsPerPage=3&page=0';
    // let url = Constants.algoliaProduction + 'drupalprod?query=' + queryParam + '&facetFilters=[' + lang + ',["moderation_state:published"]]&hitsPerPage=3&page=0';
    let url =
      Constants.algoliaProduction +
      'drupalprod?query=' +
      queryParam +
      '&facetFilters=[' +
      lang +
      ',["status:true"]]&hitsPerPage=3&page=0';
    // let url = Constants.algoliaProduction + 'drupalprod?query=' + queryParam + '&facetFilters=[' + lang + ']&hitsPerPage=3&page=0';

    const fetchData = async () => {
      await fetch(url, options)
        .then((response) => response.json())
        .then((newResponse) => {
          setTopSearchItems((previousResponse) => [
            ...previousResponse,
            ...newResponse.hits,
          ]);
        });
    };
    fetchData();
  };

  const handleSearch = (search) => {
    setQuery(search.length !== 0 ? [search] : []);
    // setFilterProps([]);
    // setFacetFilters([]);
    setPage(0);
    setApplyFilter(false);
  };

  const triggerFilter = (filters) => {
    setFilterProps(filters);

    let searchFilters = [];
    for (let filter of filters) {
      searchFilters.push(filter?.field_source_translation);
    }

    setFacetFilters(searchFilters);
    setPage(0);
    setApplyFilter(false);
  };

  // const handleClick = (event, urlAlias) => {
  //     event.stopPropagation();
  //     if(urlAlias.url.length !== 0){
  //         if (isLinkAbsolute(correctDomainForLanguage(urlAlias.language)+urlAlias.url)){
  //             window.open(correctDomainForLanguage(urlAlias.language)+urlAlias.url, '_blank');
  //         }else{
  //             router.push(correctDomainForLanguage(urlAlias.language)+urlAlias.url);
  //         }
  //     }
  // }

  const triggerFilterFromRedirect = (filter) => {
    let filters = createFiltersFromRedirect(filter, data?.categories);
    triggerFilter(filters);
    setApplyFilter(true);
    // setQuery([]);
  };

  const handleChipClick = (event, filter) => {
    event.stopPropagation();

    let url = '';

    if (typeof window !== undefined) {
      url = window.location.pathname;
    }

    for (let item of data?.categories) {
      if (item?.name === filter[0]?.name) {
        addQueryParamWithoutRedirecting(router, url, filter[0]?.name, 'type');
        triggerFilter([item]);
      }
    }

    setApplyFilter(true);
    // setQuery([]);
  };

  const renderCategories = (categories) => {
    if (typeof categories == 'object') {
      return categories.map((item, index) => {
        return (
          <div onClick={(event) => handleChipClick(event, [{ name: item }])}>
            {item}
          </div>
        );
      });
    } else {
      return categories;
    }
  };

  const clearAll = () => {
    setFilterProps([]);
    triggerFilter([]);
    setLanguageFilter([]);
    // setQuery([]);
    if (query.length > 0) {
      setQuery([]);
    }
    setShowMore([]);
    setCloseFilters(false);
  };

  const clearQuery = () => {
    setQuery([]);
    setIsSearchActive(false);
    setIsFilterActive(true);
  };

  const getMostPopularSearchTerms = () => {
    // fetch('https://analytics.algolia.com/2/searches?index=drupal', options)
    fetch('https://analytics.algolia.com/2/searches?index=drupalprod', options)
      .then((response) => response.json())
      .then((actualData) => {
        getTopSearchesFromAlgolia(actualData);
      });
  };

  const handleScroll = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const getTopSearchesFromAlgolia = (searchTerms = []) => {
    if (!searchTerms) {
      return;
    }

    for (let item of searchTerms.searches) {
      if (item.nbHits !== 0) {
        fetchAlgoliaMostPopular(item.search);
      }
    }
  };

  const renderSearchCards = (data, length = -1) => {
    let result = [];

    if (!data) {
      return;
    }

    if (data.length === 0) {
      result = [];
    }

    if (length !== -1) {
      data = [...new Set(data)].slice(0, length);
    }

    for (let item of data) {
      result.push(
        // <div className={'search-card'} onClick={(event) => handleClick(event, item.url ? correctUrlFormatForRouting(item.url) : '')}>
        <a
          className={'search-card'}
          href={item.url ? correctUrlFormatForRouting(item?.url).url : ''}
        >
          <div className={'img-container'}>
            {renderCardImage(item)}
            {/* <Image layout="fill" alt="search-image" src={item?.banner_image ? item?.banner_image : '/images/placeholder.png' } /> */}
            {/* <Image layout="fill" alt="search-image" src={item?.thumbnail_image ? item?.thumbnail_image : '/images/placeholder.png' } /> */}
            {/* <img src={item.social_image_url ? item.social_image_url : '/images/placeholder.png'} alt={'alt'}/> */}
          </div>
          <div className={'card-text-container'}>
            <div className={'card-text'}>
              <p>
                {item?.content_type ? item?.content_type : ''}
                {item?.field_time_to_read ? ',' : ''}{' '}
                {item?.field_time_to_read ? `${item?.field_time_to_read}` : ''}
              </p>
            </div>
            <span className={'title'}>
              {item?.field_template_title
                ? applyTruncationForScreen(
                    item?.field_template_title,
                    42,
                    55,
                    55,
                    30
                  )
                : applyTruncationForScreen(item?.title, 42, 60, 60, 30)}
            </span>
          </div>
          <div
            className={'second-category tag-list'}
            onClick={(event) => {
              handleChipClick(event, [
                { name: item?.category_name ? item?.category_name : '' },
              ]);
              handleScroll();
            }}
          >
            {item?.category_name ? renderCategories(item?.category_name) : ''}
          </div>
        </a>
      );
    }

    return result;
  };

  function renderSearchResults(data, global) {
    if (!data) {
      return;
    }

    if (data.length === 0) {
      return (
        <div className={'no-result'}>
          <h2>
            {global?.field_no_results_text?.[0]?.value ||
              'No results were found. Please check the spelling, reset any filters and try again.'}
          </h2>
          <div
            className={'clear-all'}
            onClick={() => {
              clearAll();
            }}
          >
            {global?.field_clear_all_label?.[0]?.value ||
              'Clear all search terms.'}
          </div>
          <div className={'most-popular'}>
            <h2>
              {global?.field_most_popular_text?.[0]?.value || 'Most popular'}
            </h2>
            <div className={'search-results'}>
              {renderSearchCards(topSearchItems, 12)}
            </div>
          </div>
        </div>
      );
    }

    return renderSearchCards(data);
  }

  return (
    <div className={'resource'}>
      <ResourceTitle
        title={data?.field_resources_title[0]?.value}
        subtitle={data?.field_resources_subtitle[0]?.value}
        placeholder={'Search All Resources...'}
      />
      <ResourceFilter
        clearQuery={clearQuery}
        isSearchActive={isSearchActive}
        isFilterActive={isFilterActive}
        setIsSearchActive={setIsSearchActive}
        setIsFilterActive={setIsFilterActive}
        search={data?.field_search_resources[0]?.value}
        title={data?.field_filter_resources[0]?.value}
        filterProps={filterProps}
        triggerFilter={triggerFilter}
        data={assemble(data?.categories)}
        onSearchChange={handleSearch}
        handleFilterApply={setApplyFilter}
        searchQuery={query}
        setQuery={setQuery}
        languageFilter={languageFilter}
        setLanguageFilter={setLanguageFilter}
        screenWidth={windowSize.width}
        clearAll={clearAll}
        closeFilters={closeFilters}
        setCloseFilters={setCloseFilters}
        cancelText={isItUndefined(global?.field_cancel_text_translation)}
        applyFilterText={isItUndefined(global?.field_apply_filters_text)}
        clearAllText={isItUndefined(global?.field_clear_all_text)}
        selectedText={isItUndefined(global?.field_selected_text)}
        seeMoreText={isItUndefined(global?.field_see_more_text)}
        seeLessText={isItUndefined(global?.field_see_less)}
      />
      {results && (filterProps.length !== 0 || query.length > 0) ? (
        <div className={'search-results-header inner-container'}>
          <h2>{global?.field_results_label?.[0]?.value || 'Results'}</h2>
          <div className={'horizontal-line'} />
          {isLoading ? (
            <div className={'loader'}>
              <CircularProgress />
            </div>
          ) : (
            <Fragment>
              <div className={'search-results'}>
                {renderSearchResults(showMore, global, data)}
              </div>
              {algoliaData.page + 1 !== algoliaData.nbPages &&
                algoliaData.nbHits !== 0 && (
                  <div
                    className={'show-more'}
                    onClick={() => setPage(page + 1)}
                  >
                    Show More
                  </div>
                )}
            </Fragment>
          )}
        </div>
      ) : (
        <>
          <ResourceContent
            global={global}
            title={isItUndefined(data?.field_featured_title)}
            featured={data?.featured}
            onChipClick={handleChipClick}
            width={width}
          />
          <ResourceLatest
            title={data?.field_latest_insights[0]?.value}
            latest={data?.latest}
            onChipClick={handleChipClick}
          />
          <ResourceWatch data={data?.mutlimedia_block[0]} width={width} />
          <ResourcePopular
            title={data?.field_popular_downloads[0]?.value}
            posts={posts}
            onChipClick={handleChipClick}
            width={width}
          />
        </>
      )}
    </div>
  );
};

export async function categoriesProps(endpoint, lang) {
  return await fetchData(endpoint, 'template-categories?lang=' + lang);
}

export async function popularDownloadsProps(endpoint, lang) {
  const res = await fetch(
    endpoint.apiV2Url + 'popular-downloads?langcode=' + lang,
    Constants.headers
  );
  // return fetchData('popular-downloads?lang+' + lang)
  return await res.json();
}

export async function popularDownloadsBlockProps(
  endpoint,
  lang,
  cats,
  count,
  type,
  dupes,
  nodeId
) {
  const res = await fetch(
    endpoint.apiV2Url +
      'popular-downloads-tid?tid=' +
      cats +
      '&langcode=' +
      lang +
      '&count=' +
      count +
      '&case=' +
      type +
      '&dupes=' +
      dupes,
    Constants.headers
  );
  // return fetchData('popular-downloads?lang+' + lang)
  return await res.json();
}

// export async function featuredProps(data) {
//     return fetchData('templates?featured=1&lang=' + data.langcode);
// }

export async function latestProps(endpoint, lang) {
  const res = await fetch(
    endpoint.apiV2Url + 'latest-insights?langcode=' + lang,
    Constants.headers
  );
  return await res.json();
}

export async function productFamilyCategoriesProps(endpoint, lang) {
  return await fetchData(endpoint, 'product-family-categories?lang=' + lang);
}
