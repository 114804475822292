import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  marketoCookieRedux: {},
};

export const marketoCookieSlice = createSlice({
  name: 'marketoCookieRedux',
  initialState,
  reducers: {
    setMarketoCookieRedux: (state, action) => {
      state.marketoCookieRedux = action.payload;
    },
  },
});

export const { setMarketoCookieRedux } = marketoCookieSlice.actions;

export default marketoCookieSlice.reducer;
