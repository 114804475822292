import { Modal, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import Marketo from '../marketo';
import { isItUndefined } from '../../util/DataUtil';

const FacilityModal = ({ children, trigger, setTrigger, global, lang }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  children = {
    ...children,
    data: {
      trigger,
      setTrigger,
      isSubmitted,
      setIsSubmitted,
    },
    field_marketo_form_ids: [
      { value: isItUndefined(children?.field_marketo_form_ids) },
    ],
    lang,
  };

  const handleClose = () => {
    delete window.MktoForms2;
    setTrigger(false);
  };

  return trigger ? (
    <Modal
      open={trigger}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {/*<Paper>*/}
      {/*    <Marketo ctaPopUp={true} close={() => setTrigger(false)} global={global}>{children}</Marketo>*/}
      {/*</Paper>*/}
      <div className={'paper facility-modal'}>
        {/*<Marketo ctaPopUp={true} close={() => setTrigger(false)} global={global}>{children}</Marketo>*/}
        <Marketo
          lang={lang}
          ctaPopUp={true}
          close={handleClose}
          global={children}
        >
          {children}
        </Marketo>
      </div>
    </Modal>
  ) : null;
};

export default FacilityModal;
