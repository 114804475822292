export const extractMetaTags = (data, titleTag) => {
  let metaTags = [];
  let canonicalUrl = '';
  let description = '';

  if (data.field_meta_tags && data.field_meta_tags.length > 0) {
    const metaData = data.field_meta_tags[0];

    metaTags = Object.entries(metaData)
      .map(([key, value]) => {
        if (key === 'canonical_url') {
          canonicalUrl = value;
          return null;
        }
        if (key === 'title') {
          titleTag = value;
        }
        if (key === 'description') {
          description = value;
        }
        return [key.replace('og_', 'og:'), value];
      })
      .filter(Boolean);
  }

  if (titleTag) {
    metaTags.push(['og:title', titleTag]);
  }
  if (description) {
    metaTags.push(
      ['og:description', description],
      ['twitter:description', description]
    );
  }

  return { metaTags, canonicalUrl, titleTag, description };
};

export const renderMetaTags = (metaTags) => {
  return metaTags.map(([key, value]) => (
    <meta key={key} name={key} content={value} />
  ));
};
