import { isLinkAbsolute, formatCountryIdString } from './DataUtil';
import { Constants } from './Constants';
import Link from 'next/link';

export const renderLogin = (mobile, globalMetrics) => {
  return globalMetrics?.field_header_login_links.map((item) => {
    return (
      <Link href={item?.uri ? item?.uri : ''} passHref>
        <a
          target={isLinkAbsolute(item?.uri) ? '_blank' : '_self'}
          className={'a-header-link login-item'}
        >
          {item.title}
          {mobile && <img alt={'arrow-right'} src={'/images/arrow-down.png'} />}
        </a>
      </Link>
    );
  });
};

export const isSticky = (sticky) => {
  // if (window.innerWidth < 992){
  //     return
  // }

  const header = document.querySelector('header');
  const body = document.querySelector('body');
  const scrollTop = window.scrollY;

  if (scrollTop >= 85 || sticky === true) {
    header?.classList.add('sticky');
    body?.classList.add('padding-top');
  } else if (scrollTop < 1) {
    header?.classList.remove('sticky');
    body?.classList.remove('padding-top');
  }
};

export const fomratCountryHref = (item, pageTranslationPaths, host) => {
  const { frontend } = host;
  let langAlias = '';
  // Extract the correct frontend URL based on the host
  let frontendUrl = frontend.includes('uat')
    ? frontend.replace('uat', `uat-${getLocaleAlias(item.langCode)}`)
    : frontend;
  frontendUrl = frontendUrl.includes('https://digitalrealty.com')
    ? getDigitalRealtyUrl(item.langCode)
    : frontendUrl;
  frontendUrl = frontendUrl.includes('frontend')
    ? frontendUrl.replace('frontend', getLocaleAlias(item.langCode))
    : frontendUrl;

  // Find the correct langAlias from pageTranslationPaths
  if (pageTranslationPaths) {
    pageTranslationPaths.forEach((pageTran) => {
      if (pageTran[item.langCode]) {
        langAlias = pageTran[item.langCode].startsWith('/')
          ? pageTran[item.langCode].slice(1)
          : pageTran[item.langCode];
        if (langAlias === 'home' || langAlias === '/home') {
          langAlias = '';
        }
      }
    });
  }

  // Use the specified item.domain if available
  if (item.domain) {
    return item.domain;
  }

  // Return the formatted URL with langAlias or default to '/'
  if (frontendUrl.includes('uat')) {
    return `${frontendUrl}${langAlias || ''}`;
  }

  // return `${frontendUrl}/${langAlias || ''}`;
  return `${frontendUrl}${langAlias.length === 0 ? '' : '/' + langAlias}`;
};

// Helper function to map language codes to locale aliases
const getLocaleAlias = (langCode) => {
  const localeMap = {
    'zh-hans': 'cn',
    ko: 'kr',
    ja: 'jp',
    'en-gb': 'uk',
    el: 'gr',
  };
  return localeMap[langCode] || langCode;
};

// Helper function to get the correct Digital Realty URL based on language code
const getDigitalRealtyUrl = (langCode) => {
  const urlMap = {
    'en-gb': Constants.uk,
    ch: Constants.ch,
    ko: Constants.kr,
    el: Constants.gr,
    'zh-hans': Constants.cn,
    asia: Constants.asia,
    at: Constants.at,
    be: Constants.be,
    dk: Constants.dk,
    fr: Constants.fr,
    de: Constants.de,
    ie: Constants.ie,
    nl: Constants.nl,
    es: Constants.es,
    se: Constants.se,
  };
  return urlMap[langCode] || '';
};

export const formatGlobalHref = (item, pageTranslationPaths, host) => {
  let langAlias = '';

  pageTranslationPaths?.forEach((pageTran) => {
    if (!pageTran[item.langCode]) {
      return;
    }

    langAlias = pageTran[item.langCode];
    if (langAlias === '/home' || langAlias === 'home') {
      langAlias = '';
    }
  });

  if (langAlias.startsWith('/')) {
    langAlias = langAlias.slice(1);
  }

  if (!langAlias) {
    return host.frontend;
  }
  return host.frontend + langAlias;
};

export const renderCountries = (
  data,
  global,
  pageTranslationPaths,
  host,
  formatIdStrings
) => {
  if (!data || data?.length == 0) {
    return;
  }

  let ret = [];

  if (global) {
    ret.push(
      <div className={'region'}>
        <div className={'languages-container'}>
          {
            <a
              key={'region'}
              onClick={() => {
                let langAlias = '';

                pageTranslationPaths?.forEach((pageTran) => {
                  if (!pageTran[data[0].langCode]) {
                    return;
                  }

                  langAlias = pageTran[data[0].langCode];
                });

                if (langAlias.startsWith('/')) {
                  langAlias = langAlias.slice(1);
                }

                if (!langAlias) {
                  window.location.replace(host.frontend);
                  return;
                }
                window.location.replace(host.frontend + langAlias);
              }}
              className={'lang-item span-header-link'}
            >
              <img src={'/images/flags/' + data[0].flagCode} alt={'flag'} />
              <a
                id={`country-${formatCountryIdString(data[0]?.name)}-link`}
                target={data[0]?.domain ? '_blank' : '_self'}
                href={formatGlobalHref(data[0], pageTranslationPaths, host)}
              >
                {data[0].name}
              </a>
            </a>
          }
        </div>
      </div>
    );

    return ret;
  }

  const column1Length = Math.floor(data[2]?.children?.length / 2);
  const column1 = data[2]?.children?.slice(0, column1Length);
  const column2 = data[2]?.children?.slice(column1Length);

  data.forEach((item, index) => {
    if (index === 0) {
      return;
    }
    if (
      index == 2 &&
      typeof window !== 'undefined' &&
      window.innerWidth >= 1280
    ) {
      ret.push(
        <div className={'region'}>
          <span className={'region-name'}>{item.name}</span>
          <div className={'languages-container'}>
            <div className="column-1">
              {column1.map((item, index) => {
                return (
                  <a
                    id={`country-${formatIdStrings(item?.name)}-link`}
                    target={item?.domain ? '_blank' : '_self'}
                    href={fomratCountryHref(item, pageTranslationPaths, host)}
                    key={index}
                    className={
                      'lang-item span-header-link no-hover-' + item.helperItem
                    }
                  >
                    {item.flagCode && (
                      <img
                        style={{ width: item.width ? item.width : '' }}
                        src={'/images/flags/' + item.flagCode}
                        alt={'flag'}
                      />
                    )}
                    {item.name}
                  </a>
                );
              })}
            </div>
            <div className="column-2">
              {column2.map((item, index) => {
                const flagClass =
                  item.langCode === 'asia'
                    ? 'square-flag'
                    : item.langCode === 'ch'
                      ? 'square-flag'
                      : '';
                return (
                  <a
                    id={`country-${formatIdStrings(item?.name)}-link`}
                    target={item?.domain ? '_blank' : '_self'}
                    href={fomratCountryHref(item, pageTranslationPaths, host)}
                    key={index}
                    className={
                      'lang-item span-header-link no-hover-' + item.helperItem
                    }
                  >
                    {item.flagCode && (
                      <img
                        style={{ width: item.width ? item.width : '' }}
                        src={'/images/flags/' + item.flagCode}
                        alt={'flag'}
                        className={flagClass}
                      />
                    )}
                    {item.name}
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      );
      return;
    }

    ret.push(
      <div className={'region'}>
        <span className={'region-name'}>{item.name}</span>
        <div className={'languages-container'}>
          {item.children.map((item, index) => {
            const flagClass =
              item.langCode === 'asia'
                ? 'square-flag'
                : item.langCode === 'ch'
                  ? 'square-flag'
                  : '';
            return (
              <a
                id={`country-${formatIdStrings(item?.name)}-link`}
                target={item?.domain ? '_blank' : '_self'}
                href={fomratCountryHref(item, pageTranslationPaths, host)}
                key={index}
                className={
                  'lang-item span-header-link no-hover-' + item.helperItem
                }
              >
                {item.flagCode && (
                  <img
                    style={{ width: item.width ? item.width : '' }}
                    src={'/images/flags/' + item.flagCode}
                    alt={'flag'}
                    className={flagClass}
                  />
                )}
                {item.name}
              </a>
            );
          })}
        </div>
      </div>
    );
  });

  return ret;
};

export const handleRouteChange = (url) => {
  isSticky();
};

export const getHamburgerClass = (openMenu, showHamburger) => {
  if (openMenu && !showHamburger) {
    return 'hamburger hamburger--collapse is-active';
  }

  return 'hamburger hamburger--collapse';
};

export function triggerChange(showTalkForm, setShowTalkForm) {
  if (showTalkForm) {
    // let home = document.getElementsByClassName('home');
    let home = document.getElementsByTagName('main');
    if (home !== undefined && home.length > 0) {
      home[0].addEventListener('click', function () {
        setShowTalkForm(false);
      });
    }
  }
}

export const scrollUp = (lastScrollTop, setLastScrollTop) => {
  let st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
  const scrollTop = window.scrollY;
  const breadcrumb = document.getElementsByClassName('breadcrumb')[0];
  const body = document.querySelector('body');

  if (st < lastScrollTop && scrollTop >= 85) {
    breadcrumb?.classList.add('sticky');
    body?.classList.add('padding-top-breadcrumb');
  } else if (scrollTop < 1 || st > lastScrollTop) {
    breadcrumb?.classList.remove('sticky');
    body?.classList.remove('padding-top-breadcrumb');
  }

  setLastScrollTop(st <= 0 ? 0 : st); // For Mobile or negative scrolling
};

export const headerLanguageName = (countryData, lang) => {
  let name = '';

  countryData.forEach((item) => {
    if (item.langCode !== lang) {
      return;
    }

    name = item.headerName;
  });

  return name;
};

export const headerLanguageImage = (countryData, lang) => {
  const languageImageMap = {
    'en-gb': 'uk.png',
    ko: 'korea.png',
    ja: 'japan.png',
    'zh-hans': 'china.png',
    el: 'greece.png',
    // Add more mappings as needed
  };

  if (lang in languageImageMap) {
    return languageImageMap[lang];
  }

  // If the language code is not found in the map, return the flag code
  let flagCode = '';
  countryData.forEach((item) => {
    if (item.langCode === lang) {
      flagCode = item.flagCode;
    }
  });
  return flagCode;
};
